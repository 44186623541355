import { Fragment } from 'react';

import { InlineLink } from '@collab/atoms';

import { usePageContext } from 'libs/notion-renderer/PageProvider';
import { getHref, InvalidLink } from 'libs/notion-renderer/utils';

const createText = (text: any[]) => {
  return text.map((item, index) => {
    const text = item.plain_text;
    const annotations = item.annotations;
    if (item.href) {
      return (
        <ParagraphLink key={index} href={item.href}>
          {text}
        </ParagraphLink>
      );
    } else if (annotations.italic && annotations.bold) {
      return (
        <strong key={index}>
          <em>{text}</em>
        </strong>
      );
    } else if (annotations.bold) {
      return <strong key={index}>{text}</strong>;
    } else if (annotations.italic) {
      return <em key={index}>{text}</em>;
    } else {
      return <Fragment key={index}>{text}</Fragment>;
    }
  });
};

const ParagraphLink: React.FC<{ href: string; children: string }> = ({
  href,
  children,
}) => {
  const { pageMetas, pagePath, headings } = usePageContext();
  let modifiedHref;

  try {
    modifiedHref = getHref(href, pageMetas, headings);
  } catch (e) {
    if (e instanceof Error) {
      throw new InvalidLink(pagePath, children, e.message);
    }
    throw e;
  }

  return <InlineLink href={modifiedHref}>{children}</InlineLink>;
};

export default createText;
