import createText from 'libs/notion-renderer/createText';
import { NotionProps } from 'notion/types';

const NotionParagraph: React.FC<NotionProps> = ({
  block: {
    paragraph: { text },
  },
}) => <p className="text-secondary oip-text-max-width">{createText(text)}</p>;

export default NotionParagraph;
